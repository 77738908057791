import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './en/common.json';
import { default as zsCommon } from './zs/common.json';
import enHome from './en/home.json';
import { default as zsHome } from './zs/home.json';
import enCategory from './en/category.json';
import enSales from './en/sales.json';
import enProduct from './en/product.json';
import enSalesdetails from './en/salesdetails.json';
import enInventory from './en/inventory.json';
import enPurchase from './en/purchase.json';
import enPurchaseList from './en/purchaseList.json';
import enBillPayment from './en/billPayment.json';
import enDashboard from './en/dashboard.json';
import enUserManage from './en/usermanage.json';
import enSupplierManage from './en/suppliermanage.json';
import enBranchManage from './en/baranchManage.json';
import enCustomer from './en/customer.json';
import enReturnList from './en/ReturnSaleList.json';
import enReturn from './en/return.json';
import enAppRoute from './en/appRoutes.json';
import enCompany from './en/company.json';
import enMessage from './en/message.json';
import enMachine from './en/machine.json';
import enPendingList from './en/pendingList.json';
import enPromo from './en/promo.json';
import enAddress from './en/address.json';
import enProfit from './en/profit.json';
import enProfitReport from './en/profitReport.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common: enCommon,
    company: enCompany,
    home: enHome,
    category: enCategory,
    sales: enSales,
    product: enProduct,
    salesdetails: enSalesdetails,
    inventory: enInventory,
    purchase: enPurchase,
    purchaseList: enPurchaseList,
    billPayment:enBillPayment,
    dashboard:enDashboard,
    usermanage:enUserManage,
    suppliermanage:enSupplierManage,
    branchManage:enBranchManage,
    customer:enCustomer,
    returnList: enReturnList,
    return:enReturn,
    appRoute:enAppRoute,
    message:enMessage,
    machine:enMachine,
    pendingList:enPendingList,
    promo: enPromo,
    address: enAddress,
    profit:enProfit,
    profitReport:enProfitReport,
  },
  zs: {
    common: zsCommon,
    company: enCompany,
    home: zsHome,
    category: enCategory,
    sales: enSales,
    product: enProduct,
    salesdetails: enSalesdetails,
    inventory: enInventory,
    purchase: enPurchase,
    purchaseList: enPurchaseList,
    billPayment:enBillPayment,
    dashboard:enDashboard,
    usermanage:enUserManage,
    suppliermanage:enSupplierManage,
    branchManage:enBranchManage,
    customer:enCustomer,
    returnList: enReturnList,
    return:enReturn,
    appRoute:enAppRoute,
    message:enMessage,
    machine:enMachine,
    pendingList:enPendingList,
    profit:enProfit,
    profitReport:enProfitReport,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: [
    'home',
    'common',
    'company',
    'category',
    'product',
    'inventory',
    'salesdetails',
    'sales',
    'purchase',
    'purchaseList',
    'billPayment',
    'dashboard',
    'usermanage',
    'suppliermanage',
    'branchManage',
    'customer',
    'returnList',
    'return',
    'appRoute',
    'message',
    'machine',
    'pendingList',
    'profit',
    'profitReport'
  ],
  defaultNS,
  resources,
});

// Change language functionality
// i18n.changeLanguage('zs');
export default i18n;
